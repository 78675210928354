import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'free-search',
  templateUrl: './free-search.component.html',
  styleUrls: ['./free-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class FreeSearchComponent implements OnInit {

  constructor() { }

  @Input() freeSearchText: string = "";
  @Input() searchText: string;

  @Output() freeSearchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputSearch') inputSearch: ElementRef;

  ngOnInit(): void {

  }

  freeSearchDelay() {
    if (this.freeSearchText.length <= 3)
      this.freeSearchTextChange.emit(this.freeSearchText);
  }
  freeSearchChange() {
    if (this.freeSearchText.length > 3)
      this.freeSearchTextChange.emit(this.freeSearchText);
  }
  clearSearch() {
    this.freeSearchText = "";
    this.freeSearchDelay();
    this.inputSearch.nativeElement.focus();
  }


  ngAfterViewInit() {
    this.inputSearch.nativeElement.focus();
  }
}
