import { IframeType, PaymentPageType } from "shared/src/enum";
import { PaymentPageCurrency } from "./payment-page-currency";
import { PaymentPageField } from "./payment-page-field";
import { PaymentPageHeader } from "./payment-page-header";
import { PaymentPagePaymentType } from "./payment-page-payment-type";
import { PaymentPageSettings } from "./payment-page-settings";
import { PaymentPageAdvancedSettings } from "./payment-pages-advanced-settings";


export class CategoriesAndProducts {
  produtsWithoutCategory: any[];
  categories: any[];
}

export class PaymentPage {
  id: number;
  guid: string;
  token: string;
  pageType: PaymentPageType;
  iframeType?: IframeType;
  terminalId: number;
  header: PaymentPageHeader;
  advancedSettings: PaymentPageAdvancedSettings;
  customerFields: PaymentPageField[];
  paymentFields: PaymentPageField[];
  dynamicPaymentFields: PaymentPageField[];
  dynamicCustomerFields: PaymentPageField[];
  settings: PaymentPageSettings = new PaymentPageSettings();
  paymentTypes: PaymentPagePaymentType[];
  currencies: PaymentPageCurrency[];
  clientId?: number;
  response: object;
  projectId?: number;
  hasBankOption?: boolean;
  hasCreditCardOption?: boolean;
  hasUpayBitOption?: boolean;
  hasGamaBitOption?: boolean;
  products: any[];
  packagesDetails: any;
  signatureExists?: boolean;
  kehilotParameters?: any;
  campaignDetails: any;
  categoriesAndProductsRoot: string;
  policySiteCompanyDetails: any;
  clubsSettings: any;
}

