<div class="p-0 flex col-12 my-2 grid m-0" style="border: 1px solid black;border-radius: 5px;"
    *ngIf="type!=productTemplateType.PaymentPage || p.max!=0">
    <div class="grid m-0 col-12 p-1">
        <div class=" p-0 " [ngClass]="type==productTemplateType.PaymentPage?'col-12 lg:col-9':'col-12 lg:col-7'">
            <div class="grid m-0">
                <div class="col-8"
                    [ngStyle]="{'background': p.quantity>0 && type==productTemplateType.PaymentPage?'#ffa5001f':''}"
                    style="overflow: hidden; height: 100%;">
                    <div class="flex gap-1">
                        <div *ngIf="type==productTemplateType.Management" class="trashBtn" (click)="removeProduct()">
                            <i class="pi pi-trash" style="font-size: 1rem"></i>
                        </div>
                        <div *ngIf="p.quantity>0 && type==productTemplateType.PaymentPage">
                            <img class="size" style="height: 1rem;"
                                src="../../../assets/images/servicesIcons/connect.png" />
                        </div>
                        <div class="fw-700" style="min-height: 2rem;line-height: 1.5rem;">{{p.productName}}
                            <!-- <span
                                class="font-light mr-1">#{{p.makat}}</span> -->
                            </div>
                    </div>
                    <div class="fs-small flex align-items-end" style="min-height:2rem;line-height: 1rem;">
                        {{p.productDescription}}</div>

                </div>

                <div class="col-4 p-1 flex  flex-column justify-content-center"
                    style="border-left: 1px solid;border-right: 1px solid;color: orange;">
                    <div class="align-self-end">
                        <div *ngIf="p.discountPercentage>0 && !p.enableEditPrice" class="fs-xsmall align-self-end"
                            style="background: yellow;color: black;width: 2rem;text-align: center;">
                            {{p.discountPercentage}}%-
                        </div>
                    </div>
                    <div class="flex flex-column align-self-center align-items-center">
                        <div class="fw-700 " *ngIf="!p.enableEditPrice">{{p.price| currency:"ILS"}}</div>
                        <p-inputNumber *ngIf="p.enableEditPrice" [(ngModel)]="p.price" [min]="0"
                            styleClass="flex align-items-center" decrementButtonClass="inputNumberButton"
                            incrementButtonClass="inputNumberButton" [size]="3" [showButtons]="false"
                            buttonLayout="horizontal" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                            spinnerMode="horizontal" (onBlur)="changeProductField()"></p-inputNumber>
                        <div *ngIf="p.priceBeforDiscount>0 && p.priceBeforDiscount!=p.price" class="fs-small "
                            style="text-decoration: line-through;    color: black;">
                            {{p.priceBeforDiscount| currency:"ILS"}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-0 col-12  lg:col-3 p-3 lg:p-0 orangeBorderTop flex  flex-column align-items-center "
            [class.justify-content-between]="type==productTemplateType.PaymentPage &&(p.min>0 || p.max!=null)"
            [ngClass]="type==productTemplateType.PaymentPage &&(p.min>0 || p.max!=null)?'justify-content-end':'justify-content-center'"
            *ngIf="type==productTemplateType.PaymentPage">
            <div *ngIf="type==productTemplateType.PaymentPage &&(p.min>0 || p.max!=null)" style="min-height: 0.75rem;">
            </div>
            <p-inputNumber [(ngModel)]="p.quantity" [min]="p.min" [max]="p.max" [allowEmpty]="p.min==0"
                styleClass="flex align-items-center" decrementButtonClass="inputNumberButton"
                incrementButtonClass="inputNumberButton" [size]="3" [showButtons]="true" buttonLayout="horizontal"
                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" spinnerMode="horizontal"
                (onBlur)="changeProductFieldQuantity()"></p-inputNumber>

            <div class="text-center" style="font-size: x-small;">
                <div *ngIf="type==productTemplateType.PaymentPage &&(p.min>0 || p.max!=null)">
                    <span *ngIf="p.min>0">מינימום:{{p.min}} </span><span *ngIf="p.max!=null">מקסימום:
                        {{p.max}} </span>
                </div>
            </div>
        </div>




        <div class=" col-12  sm:col-5 p-3 sm:p-1 orangeBorderTop flex justify-content-center align-items-center"
            *ngIf="type==productTemplateType.Management">
            <div class="col-6">
                <label class="font-bold sm:px-3">כמות מינימום</label>
                <p-inputNumber [(ngModel)]="p.min" [min]="0" [max]="p.max" styleClass="flex align-items-center"
                    class="limitInput" decrementButtonClass="inputNumberButton" incrementButtonClass="inputNumberButton"
                    [size]="3" [showButtons]="false" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus" spinnerMode="horizontal" [allowEmpty]="false"
                    (onBlur)="changeProductField()"></p-inputNumber>
            </div>
            <div class="col-6">
                <label class="font-bold sm:px-3">כמות מקסימום</label>
                <p-inputNumber [(ngModel)]="p.max" [min]="p.min" styleClass="flex align-items-center" class="limitInput"
                    decrementButtonClass="inputNumberButton" incrementButtonClass="inputNumberButton" [size]="3"
                    [showButtons]="false" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus" spinnerMode="horizontal"
                    (onBlur)="changeProductField()"></p-inputNumber>
            </div>
        </div>
    </div>

    <div *ngIf="type==productTemplateType.PaymentPage && p.dynamicFields.length" class="col-12 p-0">
        <div class="col-12" *ngIf="p.dataDynamicFields.length">
            <ng-container *ngFor="let i of getQuantityRange()">
                <product-fields [fields]="p.dataDynamicFields[i]" [index]="i"
                    (deleteProductFields)="deleteProductFields($event)"></product-fields>
            </ng-container>
            <div class="flex justify-content-center" *ngIf=" p.dataDynamicFields?.length">
                <p-button label="הוסף יחידה+" styleClass="p-button-link" (onClick)="addItem()"></p-button>
            </div>
        </div>
    </div>
</div>
