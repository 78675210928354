
<div class="py-2 flex mb-2 p-fluid" style="background-color: #8ec8ff52;border: 1px solid #1774b4;border-radius: 5px;">
    <div class="col-11">
        <div *ngFor="let field of fields" class="mb-2">
            <dynamic-field [field]="field" [flexStyle]="true"></dynamic-field>
        </div>
    </div>
    <div class="trashBtn" (click)="remove()">
        <i class="pi pi-trash" style="font-size: 1rem"></i>
    </div>
</div>
