<p-dialog header="{{ 'MENU.COMPANIES_LIST' | translate }}" [(visible)]="displayCompaines" [modal]="true"
    [style]="{'width': '90vw'}" dynamic="true" (onHide)="onBeforeDialogHide($event)">

    <div style="height: 75vh;margin-top: 0.5rem">
        <ag-grid-dynamic #grid [columns]="columns" [options]="options" *ngIf="displayCompaines"
            [getRowData]="loadData.bind(this)" [sumHeaderText]="'COMPANIES.COMPANIES'|translate"
            [(selectedData)]="selectedData" (rowDoubleClickedEvent)="rowDoubleClicked($event)"
            [(loadDataParameters)]="loadDataParameters">
        </ag-grid-dynamic>
    </div>
</p-dialog>
<ng-template #newCompany>
    <company-details [isNew]="true" [userInfo$]="userInfo$"></company-details>
</ng-template>