import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductTemplateType } from 'shared/src/enum';

@Component({
  selector: 'product-template',
  templateUrl: './product-template.component.html',
  styleUrls: ['./product-template.component.scss']
})
export class ProductTemplateComponent implements OnInit {

  @Output() changeProduct = new EventEmitter<any>();
  @Output() deleteProduct = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef,) { }
  @Input() type: ProductTemplateType = ProductTemplateType.PaymentPage;
  private _p: any;
  @Input() set p(data: any) {
    if (data) {
      this._p = data;
      if (this.type == ProductTemplateType.PaymentPage)
        this.p.dataDynamicFields = [];
      if (this.p?.quantity > 0 && this.type == ProductTemplateType.PaymentPage) {
        this.changeProductFieldQuantity();
      }

    }
  }
  get p() { return this._p }

  productTemplateType = ProductTemplateType;

  ngOnInit(): void {
  }

  changeProductField() {
    this.changeProduct.emit(this.p);
  }

  removeProduct() {
    this.deleteProduct.emit(this.p);
  }

  getQuantityRange(): number[] {
    if (!this.p?.quantity)
      return [];
    return Array(this.p.dataDynamicFields.length).fill(0).map((_, index) => index);
  }

  changeProductFieldQuantity() {
    this.changeProductField();

    let countDataFields = this.p.dataDynamicFields.length;
    if (this.p.quantity > countDataFields) {
      while (this.p.quantity !== countDataFields) {
        const deepCopyDynamicFields = JSON.parse(JSON.stringify(this.p.dynamicFields));

        this.p.dataDynamicFields.push(deepCopyDynamicFields);
        countDataFields++;
      }
    } else if (this.p.quantity < countDataFields) {
      while (this.p.quantity !== countDataFields) {
        this.p.dataDynamicFields.splice(countDataFields - 1, 1);
        countDataFields--;
      }
    }
    this.cdr.detectChanges();
  }
  addItem() {
    this.p.quantity++;
    this.changeProductFieldQuantity();
    this.cdr.detectChanges();
  }

  deleteProductFields(index) {
    this.p.dataDynamicFields.splice(index, 1);
    this.p.quantity--;
    this.cdr.detectChanges();
  }
}
